const TXT1 =
  'La plupart de nos activités se font en extérieur et nous faisons en sorte d’offrir un maximum d’activités à moindre coût ! Vous pouvez venir faire un essai avant de vous inscrire. La cotisation annuelle étant actuellement à 5 euros.';
const TXT3 = 'Qu’importe vos raisons :';
const TXT4 = '  - Que vous soyez amateur sportif et que vous trouviez ça relou de vous entraîner seul.e.';
const TXT5 = '  - Que vous n’ayez jamais fait de sport de votre vie et que vous vouliez enfin vous y mettre.';
const TXT6 = "  - Que vous n'arrivez pas à trouver seul.e la motivation de vous bouger";
const TXT7 = 'Fit for All est là pour ça !';

const TXT8 =
  "Pour vous inscrire rendez vous dans 'Adhésion' ! Vous pouvez aussi rejoindre notre discord et vous tenir informé des futurs séances ou prendre un premier contact avec nous en cliquant sur connect du widget discord ci-dessous !";

export const TXT_LIST = [TXT1, TXT3, TXT4, TXT5, TXT6, TXT7, TXT8];
