export const TITLES = {
  comforting: 'Comforting',
  fscf: 'La FSCF',
};

const TXT1 =
  'Nous avons un partenariat avec l’association Comförting ouvrant à nos adhérent·es un accès à des activités associatives. De notre côté, nous proposons d’accueillir leurs adhérent·es pour des séances de découverte sportive.';
const TXT3 =
  "Comförting est une association à but non lucratif ayant pour but le partage et l'aide dans l'optique de promouvoir un espace d'échange agréable et propice au bien-être ! Elle regroupe ses membres à travers tout un tas d’activités : sorties culturelles, DIY et plein d’autres sujets !\n";
const TXT4 = 'En rejoignant Fit for All, vous aurez donc accès à une partie du contenu proposé par Comförting !\n';
const TXT5 = 'Pour plus d’informations, visitez leur site en cliquant sur le logo !';

export const TXT_LIST = [TXT1, TXT3, TXT4, TXT5];

const TXTFSCF1 = 'Fit for All est affilié à la Fédération Sportive et Culturelle de France. \n';
const TXTFSCF2 =
  'Nous avons choisi cette fédération car c’est celle qui correspondait le plus à nos valeurs et activités. Elle est très présente dans le Rhône-Alpes Auvergne et colle parfaitement à nos valeurs humaines et environnementales.';
const TXTFSCF3 =
  'De plus, elle offre la possibilité à notre adhérence de pouvoir souscrire à un supplément d’assurance en échange d’une licence chez eux.\n';
const TFTFSCF4 = 'Pour plus d’informations, visitez leur site en cliquant sur le logo !';
export const TXTFSCF_LIST = [TXTFSCF1, TXTFSCF2, TXTFSCF3, TFTFSCF4];
