import styled from 'styled-components';

export const TITLE1 = styled.h1`
  text-align: center;
  color: ${(props) => props.theme.TITRE1};
  margin: 40px 0;

  width: 100%;
`;

export const TITLE3 = styled.h3`
  text-align: left;
  color: ${(props) => props.theme.TITRE1};
  margin: 0 20px;

  width: 100%;
`;

export const TITLE4 = styled(TITLE3)`
  text-align: center;
  margin: 0;
`;
