import React from 'react';
import _ from 'lodash';
import { Row } from '../../modules/row/row.styled';
import { LeftBlock, MidBlock } from '../../modules/contentBlock/blocks.styled';
import logo from '../../assets/logo.png';
import { Paragraphe } from '../../modules/paragraphe/paragraphe.component';
import { ACTIVITIES, IDENTITY_TEXT, PRINCIPE_TEXT, PRINCIPE_TITLES, TITLES } from './constant';
import { Logo } from '../../modules/logo/logo.styled';
import { TITLE1, TITLE3 } from '../../modules/title/title.styled';

export const HomePage = () => {
  return (
    <div>
      <TITLE1>Fit for All</TITLE1>

      <Row>
        <h2>{TITLES.identity}</h2>
      </Row>

      <Row>
        <MidBlock>
          <Logo src={logo} className="App-logo" alt="logo" />
        </MidBlock>

        <MidBlock>
          <LeftBlock>
            {_.map(IDENTITY_TEXT, (txt) => (
              <>
                <Paragraphe content={txt} />
                <br />
                <br />
              </>
            ))}
          </LeftBlock>
        </MidBlock>
      </Row>

      <Row>
        <h2>{TITLES.principe}</h2>
      </Row>

      <Row>
        <LeftBlock>
          {_.map(PRINCIPE_TEXT, (txt, index) => (
            <>
              <TITLE3>{PRINCIPE_TITLES[index]}</TITLE3>
              <Paragraphe content={txt} />
              <br />
              <br />
            </>
          ))}
        </LeftBlock>
      </Row>

      <Row>
        <h2>{TITLES.activity}</h2>
      </Row>

      <Row>
        <LeftBlock>
          {_.map(_.keys(ACTIVITIES), (disciplineName) => {
            const activity = ACTIVITIES[disciplineName];

            return (
              <>
                <TITLE3>{activity.name}</TITLE3>
                <Paragraphe content={activity.description} />
                <br />
                <br />
              </>
            );
          })}
        </LeftBlock>
      </Row>
    </div>
  );
};
