import React from 'react';
import { StyledMain } from './main.styled';
import { MainRouted } from '../../routes';

export const Main = () => {
  return (
    <StyledMain>
      <MainRouted />
    </StyledMain>
  );
};
