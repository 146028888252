export const TITLES = {
  identity: 'Qui sommes nous ?',
  principe: 'Principes et Mindset',
  activity: 'Nos activités',
};

const TXT_IDENTITY1 =
  'Fit for All est une association sportive régie par la loi du 1er Juillet 1901 créée officiellement le 30 Juillet 2020 à Lyon. Son but est de proposer des activités et conseils à ses adhérent.es afin de les remettre/garder en forme. Cette association a pour vocation de rester à petite échelle afin d’être à taille humaine pour pouvoir fournir un suivi personnalisé et qualitatif à ses adhérent.es.';
const TXT_IDENTITY2 =
  'L’association ne revendique aucun service professionnel, en revanche elle s’assure que ses animateur.ice.s aient un minimum d’expérience dans le sport qu’iels animent.\n';

export const IDENTITY_TEXT = [TXT_IDENTITY1, TXT_IDENTITY2];

export const PRINCIPE_TITLES = [
  'De Zéro à Héros',
  'Taille humaine',
  "Respect de l'activité et de l’humain",
  'Développement Durable\n',
  'Amateur pour Amateur',
];

const TXT_PRINCIPE1 =
  'Notre association n’a pas pour vocation à former les futurs champion.nes du monde de\n' +
  'demain. Nous sommes plutôt axés sur une vision d’initiation et d’entraînement. Dans l’idée, un.e futur.e adhérent.e nous rejoindra pour apprendre comment retrouver la forme en partant de zéro, ou tout simplement vouloir s’entretenir. Les animateur.ices sont là pour gérer les différences de niveaux aux cours des séances.\n';

const TXT_PRINCIPE2 =
  'Dans l’optique de fournir un suivi individuel de qualité, nous ne souhaitons pas devenir une association ayant des centaines d’adhérent.es. Nous n’avons pas vraiment de limite dictée, mais nous arrêtons d’accepter les adhésions une fois la capacité maximale atteinte vis-à-vis de nos animateur.trices.\n';

const TXT_PRINCIPE3 =
  'Nous visons à une pratique libre et égale sans jugement ni discrimination. Nous pratiquons dans un cadre compétitif sain, nous ne jugeons en aucun cas les performances de chacun.e.\n';

const TXT_PRINCIPE4 =
  'Tous nos projets sont conduits dans l’optique de répondre à des exigences éthiques, écologiques et/ou solidaires.';
const TXT_PRINCIPE5 =
  'Nous ne sommes pas des pros, et ne le revendiquons aucunement. De ce fait, la cotisation annuelle de 5 euros est surtout là pour permettre à l’asso de se développer. Les animateur.ices sont bénévoles et n’animent que pour le plaisir de partager leur expérience et des bons moments avec l’adhérence.\n';
export const PRINCIPE_TEXT = [TXT_PRINCIPE1, TXT_PRINCIPE2, TXT_PRINCIPE3, TXT_PRINCIPE4, TXT_PRINCIPE5];

export const ACTIVITIES = {
  running: {
    name: 'Course à pied',
    description:
      'Sous forme de footing adaptatif en fonction des présent.es, le parcours peut varier de 3 à 10km. Tout le monde y trouve son compte peu importe le niveau !',
  },
  sprint: {
    name: 'Sprint',
    description:
      'Des séance réalisée sur piste avec exercice de préparation, le but étant de chercher à dépasser ses limites !',
  },
  mma: {
    name: 'Art Martiaux Mixtes (MMA)',
    description:
      'Les séances axé sur l’apprentissage, afin d’apprendre à donner et à recevoir/esquiver des coups et finit généralement par des petits combats de précision.',
  },
  pilate: {
    name: 'Pilate',
    description:
      'Les séances de pilates sont à mi-chemin du yoga et du fitness. Nous apprenons à améliorer notre posture en faisant des exercices dynamiques et en musique. ',
  },
  yoga: {
    name: 'Yoga',
    description:
      "Nous organisons des séances virtuelles dans lesquelles nous faisons du Yoga dans le but d'améliorer notre bien-être et de renforcer nos muscles profonds.",
  },
  fitness: {
    name: 'Fitness',
    description:
      "Nous réalisons sur un Google meet des séances de fitness en musique. Cela permet d'améliorer collectivement le cardio de chaque personne et de faire travailler les muscles",
  },
};
