import styled, { css } from 'styled-components';
import { desktopQuery, mobileQuery } from '../../utils/responsive.utils';
import { UI } from '../../theme';

export const StyledMain = styled.div`
  display: flex;
  justify-content: center;

  background-color: ${(props) => props.theme.SECONDARY};
  color: ${(props) => props.theme.FONT};

  min-height: 100vh;

  ${mobileQuery(
    css`
      margin-bottom: ${UI.NAVBAR.HEIGHT.MOBILE};
      padding: 0 10px;
    `,
  )}

  ${desktopQuery(css``)}

  h1 {
    color: ${(props) => props.theme.TITRE1};
  }

  h2 {
    color: ${(props) => props.theme.TITRE2};
  }
`;
