import React from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Navbar } from './modules/navbar/navbar.component';
import { Main } from './modules/main/main.component';
import { Theme } from './theme';
import { store } from './store';

function App() {
  return (
    <Router>
      <div className="App">
        <Provider store={store}>
          <ThemeProvider theme={Theme}>
            <Navbar />
            <Main />
          </ThemeProvider>
        </Provider>
      </div>
    </Router>
  );
}

export default App;
