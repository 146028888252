import logo from '../../assets/logo.png';
import { Theme } from '../../theme';
import { PDF_TEXT } from './constant';

const { jsPDF } = require('jspdf');

export const generatePdf = (userState) => {
  // eslint-disable-next-line new-cap
  const doc = new jsPDF();

  const generateSection = (title, height) => {
    const titleSize = title.length * 2;

    doc.setFontSize(14);
    doc.setFillColor(Theme.PRIMARY);
    doc.rect(5, height, 100 - titleSize, 1, 'F');
    doc.text(title, 105, height + 2, { align: 'center' });
    doc.setFillColor(Theme.PRIMARY);
    doc.rect(105 + titleSize, height, 100 - titleSize, 1, 'F');
  };

  doc.setTextColor(Theme.FONT);

  // Background
  doc.setFillColor(Theme.SECONDARY);
  doc.rect(0, 0, 210, 297, 'F');

  // Header
  doc.addImage(logo, 'PNG', 5, 5, 40, 40);
  doc.setFontSize(18);
  doc.text(PDF_TEXT.assoName, 105, 20, { align: 'center' });
  doc.text(`${PDF_TEXT.object} Année 2022/2023`, 105, 30, { align: 'center' });

  doc.setFontSize(9);
  doc.text(PDF_TEXT.header1, 105, 35, { align: 'center' });

  // Section 1
  let sectionHeight = 55;
  generateSection(PDF_TEXT.section1, sectionHeight);
  doc.setFontSize(12);
  doc.text(`Nom : ${userState.nom.toUpperCase()}`, 15, sectionHeight + 15);
  doc.text(`Prénom : ${userState.prenom}`, 15, sectionHeight + 30);
  doc.text(`Age : ${userState.age}`, 15, sectionHeight + 45);

  doc.text(`Pseudo : ${userState.pseudo}`, 105, sectionHeight + 15);
  doc.text(`N° de Téléphone : ${userState.numero}`, 105, sectionHeight + 30);
  doc.text(`Mail : ${userState.email}`, 105, sectionHeight + 45);

  // Section 2
  sectionHeight = 110;
  generateSection(PDF_TEXT.section3, sectionHeight);
  doc.setFontSize(12);
  doc.text(`Nom : ${userState.nomUrgence}`, 15, sectionHeight + 15);
  doc.text(`Prénom : ${userState.prenomUrgence}`, 15, sectionHeight + 30);

  doc.text(`N° de Téléphone : ${userState.numeroUrgence}`, 105, sectionHeight + 15);
  doc.text(`Lien avec la personne : ${userState.lienUrgence}`, 105, sectionHeight + 30);

  // Section 3
  sectionHeight = 155;
  generateSection(PDF_TEXT.section4, sectionHeight);
  doc.setFontSize(12);
  doc.rect(15, sectionHeight + 7, 3, 3, 'F');
  if (userState.droitImage) {
    doc.line(15, sectionHeight + 7, 18, sectionHeight + 10);
    doc.line(15, sectionHeight + 10, 18, sectionHeight + 7);
  }
  doc.text(PDF_TEXT.droitImage, 25, sectionHeight + 10);

  // Section 4
  sectionHeight = 180;
  generateSection(PDF_TEXT.section5, sectionHeight);
  doc.setFontSize(12);
  doc.rect(15, sectionHeight + 7, 3, 3, 'F');
  if (userState.activites) {
    doc.line(15, sectionHeight + 7, 18, sectionHeight + 10);
    doc.line(15, sectionHeight + 10, 18, sectionHeight + 7);
  }
  doc.text(PDF_TEXT.activites, 25, sectionHeight + 10);

  // Section 5
  sectionHeight = 205;
  generateSection(PDF_TEXT.section6, sectionHeight);
  doc.setFontSize(12);
  doc.rect(15, sectionHeight + 7, 3, 3, 'F');
  if (userState.federation) {
    doc.line(15, sectionHeight + 7, 18, sectionHeight + 10);
    doc.line(15, sectionHeight + 10, 18, sectionHeight + 7);
  }
  doc.text(PDF_TEXT.federation, 25, sectionHeight + 10);

  // Section 6
  sectionHeight = 230;
  generateSection(PDF_TEXT.section7, sectionHeight);
  doc.setFontSize(12);
  doc.text(PDF_TEXT.declaration, 15, sectionHeight + 10);

  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  doc.text(`Le ${day}/${month}/${year}`, 15, 270);

  doc.text('Signature', 130, 270);
  doc.text('Lu et approuvé.e', 100, 280);
  doc.text(`${userState.nom.toUpperCase()} ${userState.prenom}`, 140, 280);

  doc.save(`FFA_${userState.nom.toUpperCase()}_${userState.prenom}.pdf`);
};
