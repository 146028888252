import styled from 'styled-components';

export const Button = styled.input`
  background-color: white;
  border-radius: 2em;

  &:hover {
    background-color: ${(props) => props.theme.PRIMARY};
  }
`;
