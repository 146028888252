import React from 'react';
import { CustomLink } from './errorPage.styled';

export const ErrorPage = () => {
  return (
    <div>
      <h1>On peut savoir où vous allez comme ça ?</h1>
      <p>
        Revenez donc par <CustomLink href="/">ici</CustomLink>
      </p>
    </div>
  );
};
