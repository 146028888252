import styled, { css } from 'styled-components';
import { desktopQuery, mobileQuery } from '../../utils/responsive.utils';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  color: ${(props) => props.theme.FONT};

  ${mobileQuery(css`
    flex-wrap: wrap;
  `)}

  ${desktopQuery(
    css`
      width: 50%;
      margin: 0 auto;
    `,
  )}
`;
