import styled, { css } from 'styled-components';
import { desktopQuery } from '../../../utils/responsive.utils';
import { UI } from '../../../theme';

export const StyledNavItem = styled.a`
  display: flex;
  min-height: ${UI.NAVITEM.HEIGHT.MOBILE};
  min-width: ${UI.NAVITEM.WIDTH.MOBILE};

  margin: 0 5px;

  justify-content: center;
  align-items: center;

  text-decoration: none;
  color: ${(props) => props.theme.FONT};

  &:hover {
    background-color: ${(props) => props.theme.PRIMARY};
  }

  ${desktopQuery(
    css`
      min-height: ${UI.NAVITEM.HEIGHT.DESKTOP};
      min-width: ${UI.NAVITEM.WIDTH.DESKTOP};
      margin: 0 20px;
    `,
  )}
`;
