import styled, { css } from 'styled-components';
import { desktopQuery } from '../../utils/responsive.utils';

export const Block = styled.div`
  display: flex;
  justify-content: center;
  margin: 25px 0;

  width: 100%;
`;

export const LeftBlock = styled(Block)`
  display: block;
  justify-content: left;
`;

export const MidBlock = styled(Block)`
  align-items: center;
  ${desktopQuery(
    css`
      width: 50%;
    `,
  )}
`;
