import React, { useCallback } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { TITLE1, TITLE4 } from '../../modules/title/title.styled';
import { Row } from '../../modules/row/row.styled';
import { generatePdf } from './utils';
import { FormBlock, FormSection } from './formPdf.styled';
import { Field } from './components/field.component';
import { FORM_FIELDS, PDF_TEXT } from './constant';
import { Button } from './components/button.styled';
import { getFormData } from '../../reducer/selector';
import { Block } from '../../modules/contentBlock/blocks.styled';

export const FormPdf = () => {
  const formData = useSelector(getFormData);

  const buildSection = (sectionName) => (
    <FormSection>
      {_.map(FORM_FIELDS[sectionName], (fieldObject, fieldID) => (
        <Field
          key={fieldID}
          name={fieldID}
          label={fieldObject.label}
          type={fieldObject.type}
          isNeeded={fieldObject.isNeeded}
        />
      ))}
    </FormSection>
  );

  const onSubmit = useCallback(() => {
    generatePdf(formData);
  }, [formData]);

  return (
    <div>
      <TITLE1>Générateur de fiche</TITLE1>
      <Row>
        <FormBlock name="userData">
          <TITLE4>Coordonnées</TITLE4>
          <FormSection>{buildSection('coordonnees')}</FormSection>
          <hr />
          <TITLE4>Urgences</TITLE4>
          <FormSection>{buildSection('urgences')}</FormSection>
          <hr />
          <TITLE4>Droit à image</TITLE4>
          <Field name="droitImage" label={PDF_TEXT.droitImage} type="checkbox" />
          <hr />
          <TITLE4>Activité Associative</TITLE4>
          <Field name="activites" label={PDF_TEXT.activites} type="checkbox" />
          <hr />
          <TITLE4>Fédération</TITLE4>
          <Field name="federation" label={PDF_TEXT.federation} type="checkbox" isNeeded />
          <hr />
          <FormSection>
            <Button type="submit" value="Créer votre fiche" onClick={onSubmit} />
          </FormSection>
        </FormBlock>
      </Row>
      <Row>
        <Block>Envoyez ensuite votre fiche à : fitforall.association@gmail.com</Block>
      </Row>
    </div>
  );
};
