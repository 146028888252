export const PDF_TEXT = {
  assoName: 'Fit for All',
  object: "Fiche d'adhésion",
  header1: 'Siège social : 4 rue Petit David, 69002 LYON',
  section1: 'COORDONNÉES',
  section3: 'URGENCE',
  section4: "DROIT A L'IMAGE",
  section5: 'ACTIVITÉS ASSOCIATIVES',
  section6: 'FÉDÉRATION',
  section7: 'SIGNATURE',
  droitImage:
    'En cochant cette case j’autorise l’association Fit for All à mettre sur son site et/ou sur le Discord\n' +
    'des photos et vidéos prises dans le cadre de ses activités (sorties, événements sportifs, ...)',
  activites:
    'En cochant cette case, j’accepte la création d’un compte personnel qui me sera propre afin\n' +
    'de pouvoir suivre ma progression ainsi que l’accès aux autres membres de l’association à\n' +
    'mon pseudo et mes records.',
  federation:
    "En cochant cette case, j'atteste avoir pris connaissance de la possibilité de pouvoir souscrire\n" +
    "à une licence sportive ainsi qu'un complément d'assurance auprès de la FSCF et je me rapprocherais du\n " +
    "bureau de l'association si je désire en avoir une.",
  declaration:
    'Je certifie avoir pris connaissance du règlement intérieur et des statuts et accepte les conditions\n' +
    'qui y sont stipulées.',
};

export const FORM_FIELDS = {
  coordonnees: {
    nom: { label: 'Nom', type: 'text', isNeeded: true },
    prenom: { label: 'Prénom', type: 'text', isNeeded: true },
    pseudo: { label: 'Pseudo', type: 'text', isNeeded: true },
    numero: { label: 'Numéro', type: 'tel', isNeeded: false },
    age: { label: 'Age', type: 'number', isNeeded: true },
    email: { label: 'Email', type: 'mail', isNeeded: true },
  },
  urgences: {
    nomUrgence: { label: 'Nom', type: 'text', isNeeded: true },
    prenomUrgence: { label: 'Prénom', type: 'text', isNeeded: true },
    lienUrgence: { label: 'Lien', type: 'text', isNeeded: true },
    numeroUrgence: { label: 'Numéro', type: 'tel', isNeeded: false },
  },
  verification: {
    droitImage: { label: '', type: 'checkbox', isNeeded: false },
    activites: { label: '', type: 'checkbox', isNeeded: false },
    federation: { label: '', type: 'checkbox', isNeeded: true },
  },
};
