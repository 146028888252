import styled, { css } from 'styled-components';
import { desktopQuery, mobileQuery } from '../../utils/responsive.utils';
import { UI } from '../../theme';

export const StyledNavbar = styled.nav`
  display: flex;
  width: 100%;
  height: ${UI.NAVBAR.HEIGHT.MOBILE};

  justify-content: center;
  align-items: center;

  background-color: ${(props) => props.theme.TERTIARY};
  color: ${(props) => props.theme.FONT};

  ${mobileQuery(
    css`
      position: fixed;
      bottom: 0;
    `,
  )}

  ${desktopQuery(
    css`
      position: sticky;
      top: 0;
      height: ${UI.NAVBAR.HEIGHT.DESKTOP};
    `,
  )}
`;
