import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { HomePage } from './pages/home/homePage.component';
import { PartnersPage } from './pages/partners/partenairesPage.component';
import { JoinUsPage } from './pages/joinUs/joinUsPage.component';
import { ErrorPage } from './pages/error/errorPage.component';
import { FormPdf } from './pages/formPdf/formPdf.component';
import { HOME_LINK, JOINS_US_LINK, PARTNERS_LINK, PDF_ADHESION_LINK } from './modules/navbar/constant';

export const mainRoutes = [
  {
    path: PDF_ADHESION_LINK,
    exact: true,
    main: FormPdf,
  },
  {
    path: PARTNERS_LINK,
    exact: true,
    main: PartnersPage,
  },
  {
    path: JOINS_US_LINK,
    exact: true,
    main: JoinUsPage,
  },
  {
    path: HOME_LINK,
    exact: true,
    main: HomePage,
  },
  {
    path: '*',
    main: ErrorPage,
  },
];

export const MainRouted = () => (
  <Switch>
    {mainRoutes.map((route) => (
      <Route key={route.path} path={route.path} exact={route.exact} component={route.main} />
    ))}
  </Switch>
);
