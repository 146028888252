import React from 'react';
import styled, { css } from 'styled-components';
import Proptypes from 'prop-types';
import { desktopQuery } from '../../utils/responsive.utils';

const StyledParagraph = styled.p`
  display: inline;
  justify-items: left;
  align-items: center;

  text-indent: 1.5em;
  margin: 10px 0;

  color: ${(props) => props.theme.FONT};

  ${desktopQuery(
    css`
      text-indent: 3em;
    `,
  )}
`;

export const Paragraphe = ({ content }) => <StyledParagraph>{content}</StyledParagraph>;

Paragraphe.propTypes = {
  content: Proptypes.string.isRequired,
};
