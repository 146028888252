export const Theme = {
  PRIMARY: '#2F91D4',
  SECONDARY: '#232335',
  TERTIARY: '#181824',
  TITRE1: '#2a36b7',
  TITRE2: '#b9970c',
  FONT: '#F9F0F0',
};

/*
  Primary : Mise en evidence
  Secondary: Background
  Tertiary: Navbar
  Titre1 : H1
  Titre2 : H2
  FONT : color
*/

export const UI = {
  NAVBAR: {
    HEIGHT: {
      DESKTOP: '50px',
      MOBILE: '30px',
    },
  },
  NAVITEM: {
    HEIGHT: {
      DESKTOP: '40px',
      MOBILE: '30px',
    },
    WIDTH: {
      DESKTOP: '75px',
      MOBILE: '60px',
    },
  },
};
