import styled, { css } from 'styled-components';
import { desktopQuery } from '../../utils/responsive.utils';

export const Logo = styled.img`
  width: 100%;
  height: 100%;
  max-width: 350px;
  max-height: 350px;
  object-fit: contain;

  ${desktopQuery(
    css`
      max-width: 400px;
      max-height: 400px;
    `,
  )}
`;
