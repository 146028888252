import React from 'react';
import _ from 'lodash';
import { StyledNavbar } from './navbar.styled';
import { StyledNavItem } from './components/navItem.styled';
import { LINKS } from './constant';

export const Navbar = () => {
  return (
    <StyledNavbar>
      {_.map(LINKS, ({ href, title }) => (
        <StyledNavItem key={title} href={href}>
          {title}
        </StyledNavItem>
      ))}
    </StyledNavbar>
  );
};
