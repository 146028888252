import { UPDATE_FIELD } from './action';

const initialState = {
  form: {
    nom: '',
    prenom: '',
    pseudo: '',
    numero: 0,
    age: 0,
    email: '',
    nomUrgence: '',
    prenomUrgence: '',
    lienUrgence: '',
    numeroUrgence: 0,
    droitImage: false,
    activites: false,
    federation: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FIELD:
      return {
        ...state,
        form: {
          ...state.form,
          [action.path]: action.value,
        },
      };

    default:
      return state;
  }
};

export default reducer;
