import React from 'react';
import _ from 'lodash';
import { TITLE1 } from '../../modules/title/title.styled';
import { Row } from '../../modules/row/row.styled';
import { TITLES, TXT_LIST, TXTFSCF_LIST } from './constant';
import { LeftBlock, MidBlock } from '../../modules/contentBlock/blocks.styled';
import { Logo } from '../../modules/logo/logo.styled';
import logoComforting from '../../assets/comforting.png';
import logoFSCF from '../../assets/FSCF.png';
import { Paragraphe } from '../../modules/paragraphe/paragraphe.component';

export const PartnersPage = () => {
  return (
    <div>
      <TITLE1>Nos partenaires</TITLE1>

      <Row>
        <h2>{TITLES.comforting}</h2>
      </Row>

      <Row>
        <MidBlock>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a href="https://comforting.xyz/association" target="_blank">
            <Logo src={logoComforting} className="App-logo" alt="logo" />
          </a>
        </MidBlock>
        <MidBlock>
          <LeftBlock>
            {_.map(TXT_LIST, (txt) => (
              <>
                <Paragraphe content={txt} />
                <br />
                <br />
              </>
            ))}
          </LeftBlock>
        </MidBlock>
      </Row>

      <Row>
        <h2>{TITLES.fscf}</h2>
      </Row>

      <Row>
        <MidBlock>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a href="https://www.fscf.asso.fr/" target="_blank">
            <Logo src={logoFSCF} className="App-logo" alt="logo" />
          </a>
        </MidBlock>
        <MidBlock>
          <LeftBlock>
            {_.map(TXTFSCF_LIST, (txt) => (
              <>
                <Paragraphe content={txt} />
                <br />
                <br />
              </>
            ))}
          </LeftBlock>
        </MidBlock>
      </Row>
    </div>
  );
};
