export const HOME_LINK = '/';
export const PARTNERS_LINK = '/partenaires';
export const JOINS_US_LINK = '/rejoins-nous';
export const PDF_ADHESION_LINK = '/adhesion';

export const LINKS = {
  ffa: { title: 'Fit for All', href: HOME_LINK },
  parters: { title: 'Partenaires', href: PARTNERS_LINK },
  joinUs: { title: 'Rejoignez nous', href: JOINS_US_LINK },
  adhesion: { title: 'Adhésion', href: PDF_ADHESION_LINK },
};
