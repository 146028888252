import React, { useCallback } from 'react';
import styled from 'styled-components';
import Proptypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { updateField } from '../../../reducer/action';

const FieldBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 3px;
`;

const CheckboxFieldBlock = styled(FieldBlock)`
  flex-direction: row;
`;

const FieldName = styled.label``;

const CheckboxFieldName = styled.label`
  padding: 5px 2px;
`;

const InputBlock = styled.input`
  padding: 0.25em 0.5em;
  background-color: #fff;
  border: none;
  border-radius: 4px;

  line-height: 1;
  height: 1.75rem;
`;

export const Field = ({ name, label, type, isNeeded }) => {
  const dispatch = useDispatch();

  const isCheckbox = type === 'checkbox';

  const onFieldChange = useCallback(
    (event) => {
      const value = type === 'checkbox' ? event.target.checked : event.target.value;
      dispatch(updateField(name, value));
    },
    [dispatch, updateField],
  );

  const ChecboxOutput = (
    <CheckboxFieldBlock>
      <InputBlock id={`input-${name}`} type={type} required={isNeeded} onChange={(event) => onFieldChange(event)} />
      <CheckboxFieldName htmlFor={name}>{label}</CheckboxFieldName>
    </CheckboxFieldBlock>
  );

  const OtherOutput = (
    <FieldBlock>
      <FieldName htmlFor={name}>{label}</FieldName>
      <InputBlock id={`input-${name}`} type={type} required={isNeeded} onChange={(event) => onFieldChange(event)} />
    </FieldBlock>
  );

  return isCheckbox ? ChecboxOutput : OtherOutput;
};

Field.propTypes = {
  name: Proptypes.string.isRequired,
  label: Proptypes.string,
  type: Proptypes.string.isRequired,
  isNeeded: Proptypes.bool,
};

Field.defaultProps = {
  label: '',
  isNeeded: false,
};
