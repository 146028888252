import React from 'react';
import _ from 'lodash';
import { TITLE1 } from '../../modules/title/title.styled';
import { TXT_LIST } from './constant';
import { LeftBlock, MidBlock } from '../../modules/contentBlock/blocks.styled';

import { Paragraphe } from '../../modules/paragraphe/paragraphe.component';
import { Row } from '../../modules/row/row.styled';

export const JoinUsPage = () => {
  return (
    <div>
      <TITLE1>Rejoins nous</TITLE1>

      <Row>
        <LeftBlock>
          {_.map(TXT_LIST, (TXT) => (
            <>
              <Paragraphe content={TXT} />
              <br />
              <br />
            </>
          ))}
        </LeftBlock>
      </Row>

      <br />
      <Row>
        <MidBlock>
          <iframe
            title="Notre Discord"
            src="https://discord.com/widget?id=738785058382545017&theme=dark"
            width="100%"
            height="400"
            allowTransparency="true"
            frameBorder="0"
            sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
          />
        </MidBlock>
      </Row>
    </div>
  );
};
